<template>
  <div class="full-area padded vertically-centered desktop-wrapperx">
    <h2>{{processingFormText}}</h2>
    <div class="action-wrapper">
      <el-row class="transition-visibility" :class="{ 'invisible': !spinner }">
        <div class="spinner">
          <div class="bounce1"></div>
          <div class="bounce2"></div>
          <div class="bounce3"></div>
        </div>
      </el-row>
      <el-row class="transition-visibility" :class="{ 'invisible': !error }">
        <p class="error-wrapper">{{errorOccurredText}} <br>{{errMessage}}</p>
        <el-button
          class="button-wrapper"
          type="danger"
          round @click="sendAnswers()"
        >{{processAgainText}}</el-button>
      </el-row>

    </div>
  </div>
</template>

<script>
export default {
  name: 'TwoDimensionalTestResult',

  data () {
    return {
      error: false,
      errMessage: '',
      spinner: true
    }
  },

  computed: {
    processingFormText () {
      if (this.$store.state.survey.language === 'EN') {
        return 'Processing...'
      }
      if (this.$store.state.survey.language === 'PT') {
        return 'Processando...'
      }
      if (this.$store.state.survey.language === 'HIN') {
        return 'प्रसंस्करण...'
      }
      return 'Procesando...'
    },
    processAgainText () {
      if (this.$store.state.survey.language === 'EN') {
        return 'Try again'
      }
      if (this.$store.state.survey.language === 'PT') {
        return 'Em processo'
      }
      if (this.$store.state.survey.language === 'HIN') {
        return 'पुनः प्रयास करें'
      }
      return 'Procesar de nuevo'
    },
    errorOccurredText () {
      if (this.$store.state.survey.language === 'EN') {
        return 'An error has occurred: '
      }
      if (this.$store.state.survey.language === 'PT') {
        return 'Ocorreu um erro: '
      }
      if (this.$store.state.survey.language === 'HIN') {
        return 'एक गलती हुई है: '
      }
      return 'Ocurrió un error: '
    },
    profiles () {
      return this.$store.state.testResults.profiles
    },
    weightedTestResults () {
      return this.$store.state.survey.weightedTestResults
    }
  },

  methods: {
    restartSurvey () {
      this.$emit('restart')
    },
    calculateProfile () {
      if (!this.profiles) {
        return {}
      }
      const tr = this.weightedTestResults

      var outputProfile = ''
      this.$store.state.testResults.profiles.forEach((profile, i) => {
        var match = 0
        var minVector = profile.fields.weightedMin.split(',')
        var maxVector = profile.fields.weightedMax.split(',')
        minVector.forEach((variable, j) => {
          if (tr[j] >= minVector[j] && tr[j] <= maxVector[j]) match++
        })

        if (match === minVector.length) {
          outputProfile = Object.assign({}, profile)
        }
      })

      // Fix error with the following line
      this.$store.commit('STORE_USER_PROFILE', {
        profile: outputProfile,
        profileId: outputProfile.sys.id
      })
      this.$router.push({
        name: 'profile',
        params: {
          pid: outputProfile.sys.id
        }
      })
    }
  },

  mounted () {
    if (this.$store.state.survey.testResults && this.$store.state.survey.testResults.sys && this.$store.state.survey.testResults.sys.id && this.$store.state.testResults.length === 0) {
      this.$store.dispatch('LOAD_TEST_RESULTS', { testResultsId: this.$store.state.survey.testResults.sys.id })
        .then(() => {
          this.calculateProfile()
        })
        .catch(err => {
          console.log(err)
        })
    }

    // Enable vector testing using query vars
    var vector = this.$route.query.vector
    if (vector) {
      this.$store.commit('STORE_TEST_RESULT', {
        weightedTestResults: vector.split(',').map(i => !isNaN(parseInt(i, 10)) ? parseInt(i, 10) : i)
      })
    }

    this.calculateProfile()
  }
}
</script>

<style scoped lang="scss">
.transition-visibility {
  transition: opacity 0.2s ease-in-out;
}
.invisible {
  opacity: 0.0001;
}
.action-wrapper {
  min-height: 120px;
  position: relative;

  .el-row {
    position: absolute;
    margin: 0 auto;
    width: 100%;
  }
}
</style>
